import { UserProfile } from 'models/user-profile.model';
import Partner from '../../models/partner.model';

export type UserState = {
  authToken: string;
  userId: string;
  profile: UserProfile;
  hasUserChosenGuest: boolean;
};

export const initialState: UserState = {
  authToken: '',
  userId: '',
  profile: undefined,
  hasUserChosenGuest: false,
};

export const actions = {
  UPDATE_AUTH_TOKEN: 'UPDATE_AUTH_TOKEN',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_PARTNER_PROFILE: 'UPDATE_PARTNER_PROFILE',
  RESET_LOGGED_USER: 'RESET_LOGGED_USER',
  UPDATE_HAS_USER_CHOSEN_GUEST: 'UPDATE_HAS_USER_CHOSEN_GUEST',
};

export const userReducer = (
  state: UserState,
  action: { type: string; payload?: UserProfile | string | boolean | Partner },
) => {
  switch (action.type) {
    case actions.UPDATE_USER_PROFILE:
      // eslint-disable-next-line no-case-declarations
      const user = action.payload as UserProfile;
      return {
        ...state,
        profile: { ...user },
        userId: user._id,
      };
    case actions.UPDATE_PARTNER_PROFILE:
      // eslint-disable-next-line no-case-declarations
      const partner = action.payload as Partner;
      return {
        ...state,
        profile: {
          authInfo: {
            isValid: partner.authInfo.isValid,
          },
          billingInfo: {
            billingFirstName: partner.billingInfo.billingFirstName,
            billingLastName: partner.billingInfo.billingLastName,
            billingMobilePhoneCountryCode: partner.billingInfo.billingMobilePhoneCountryCode,
            billingMobilePhoneNumber: '',
            billingTelephone: partner.billingInfo.billingMobilePhoneNumber,
            billingCity: partner.billingInfo.billingCity,
            billingAddress: partner.billingInfo.billingAddress,
            billingCountry: partner.billingInfo.billingCountry,
            billingPostalCode: partner.billingInfo.billingPostalCode,
          },
          contactInfo: {
            isBillingAddress: false,
            transporterNote: '',
            country: partner.addressInfo.country,
            addressComplement: partner.addressInfo.address2,
            city: partner.addressInfo.city,
            contactName: partner.contactInfo.contactName,
            postalCode: partner.addressInfo.postalCode,
            address: partner.addressInfo.address1,
            fixePhoneCountryCode: '',
            fixePhoneNumber: '',
            mobilePhoneCountryCode: partner.contactInfo.phoneCountryCode,
            mobilePhoneNumber: partner.contactInfo.phone,
            lastName: '',
            firstName: partner.contactInfo.personOfContact,
            title: partner.contactInfo.title,
          },
          roles: partner.roles,
          vatNumber: partner.contactInfo.vatNumber,
          vatCountryCode: partner.contactInfo.vatCountryCode,
          email: partner.contactInfo.email,
          _id: partner.id,
          accountType: 'professional',
        },
        userId: partner.id,
      };
    case actions.UPDATE_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    case actions.RESET_LOGGED_USER:
      return {
        ...state,
        authToken: '',
        userId: '',
        profile: undefined,
        hasUserChosenGuest: false,
      };
    case actions.UPDATE_HAS_USER_CHOSEN_GUEST:
      return {
        ...state,
        hasUserChosenGuest: action.payload,
      };
    default:
      return state;
  }
};
