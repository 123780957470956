import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';
import { DeliveryPrice } from '../../models/offer-option.model';
import { OneWayRequest } from '../../models/oneway-offer-request.model';
import { FilterShipment } from '../../models/filter-shipment.model';
import { UploadCroquiRequest } from '../../models/upload-croqui.request';
import UserType from '../../models/UserType.model';
import Partner from '../../models/partner.model';
import { CreatePartnerDto } from '../../models/oneway-partner-registration.model';
import { OnewayAdminReport, OnewayReport } from '../../models/oneway-report.model';
import { getToken } from './auth-session-storage.service';

export default class ColisportBikeApi implements ColisportApiResource {
  readonly apiUrlPath = 'bike';

  constructor(readonly httpClient: AxiosInstance) {}

  async getAvailablePartners(
    isElectricBike: boolean,
    isTandemBike: boolean,
  ): Promise<AxiosResponse<Partner[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/partner/${isElectricBike}/${isTandemBike}`);
  }

  async getAllPartners(): Promise<AxiosResponse<Partner[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/allPartners`);
  }

  async addPartner(data: CreatePartnerDto): Promise<AxiosResponse<CreatePartnerDto>> {
    return this.httpClient.post(`${this.apiUrlPath}/partner`, data);
  }

  async updatePartner(data: Partial<Partner>, email: string): Promise<AxiosResponse<Partner>> {
    return this.httpClient.patch(`${this.apiUrlPath}/partner/${email}`, data);
  }

  async getPartnerByEmail(email: string): Promise<AxiosResponse<Partner>> {
    return this.httpClient.get(`${this.apiUrlPath}/partner/${email}`);
  }

  async getOffers(data: OneWayRequest): Promise<AxiosResponse<DeliveryPrice[]>> {
    return this.httpClient.post(`${this.apiUrlPath}/offers`, data);
  }

  async getShipments(query?: string): Promise<AxiosResponse<FilterShipment>> {
    return this.httpClient.get(`${this.apiUrlPath}/shipments${query !== '' ? `?${query}` : ''}`, {
      params: `shipments?${query}`,
    });
  }

  async uploadCroqui(data: UploadCroquiRequest): Promise<AxiosResponse<any>> {
    return this.httpClient.post(`${this.apiUrlPath}/upload/croqui`, data);
  }

  async downloadCroqui(
    colisportID: string,
    index: number,
    croquiType: 'check-in' | 'check-out',
  ): Promise<AxiosResponse<ArrayBuffer>> {
    return this.httpClient.get(
      `${this.apiUrlPath}/download/croqui/${colisportID}/${index}/${croquiType}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  async validatePackages(
    colisportID: string,
    onewayStoreType: UserType,
  ): Promise<AxiosResponse<void>> {
    return this.httpClient.put(
      `${this.apiUrlPath}/validate-packages/${colisportID}/${onewayStoreType}`,
    );
  }

  async validateOrderDeliveredToUser(colisportID: string): Promise<AxiosResponse<void>> {
    return this.httpClient.put(
      `${this.apiUrlPath}/validate-order-delivered-to-user/${colisportID}`,
    );
  }

  async getPartnerReportsById(
    partnerId: string,
    dateRange: string,
  ): Promise<AxiosResponse<OnewayReport>> {
    return this.httpClient.get(`${this.apiUrlPath}/report/${dateRange}/${partnerId}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }

  async getPartnerReports(dateRange: string): Promise<AxiosResponse<OnewayAdminReport>> {
    return this.httpClient.get(`${this.apiUrlPath}/report/${dateRange}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
  }
}
