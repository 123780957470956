exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-auth-orders-[email]-tsx": () => import("./../../../src/pages/auth/orders/[email].tsx" /* webpackChunkName: "component---src-pages-auth-orders-[email]-tsx" */),
  "component---src-pages-auth-verify-[token]-tsx": () => import("./../../../src/pages/auth/verify/[token].tsx" /* webpackChunkName: "component---src-pages-auth-verify-[token]-tsx" */),
  "component---src-pages-back-office-tsx": () => import("./../../../src/pages/back-office.tsx" /* webpackChunkName: "component---src-pages-back-office-tsx" */),
  "component---src-pages-bike-back-office-tsx": () => import("./../../../src/pages/bike/back-office.tsx" /* webpackChunkName: "component---src-pages-bike-back-office-tsx" */),
  "component---src-pages-bike-form-tsx": () => import("./../../../src/pages/bike/form.tsx" /* webpackChunkName: "component---src-pages-bike-form-tsx" */),
  "component---src-pages-bike-index-tsx": () => import("./../../../src/pages/bike/index.tsx" /* webpackChunkName: "component---src-pages-bike-index-tsx" */),
  "component---src-pages-bike-parcel-tsx": () => import("./../../../src/pages/bike/parcel.tsx" /* webpackChunkName: "component---src-pages-bike-parcel-tsx" */),
  "component---src-pages-bike-thank-you-tsx": () => import("./../../../src/pages/bike/thank-you.tsx" /* webpackChunkName: "component---src-pages-bike-thank-you-tsx" */),
  "component---src-pages-colisport-id-accept-payment-price-id-tsx": () => import("./../../../src/pages/[colisportID]/accept-payment/[priceID].tsx" /* webpackChunkName: "component---src-pages-colisport-id-accept-payment-price-id-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-getinvoicedata-query-params-tsx": () => import("./../../../src/pages/invoice/getinvoicedata/[queryParams].tsx" /* webpackChunkName: "component---src-pages-invoice-getinvoicedata-query-params-tsx" */),
  "component---src-pages-parcel-tsx": () => import("./../../../src/pages/parcel.tsx" /* webpackChunkName: "component---src-pages-parcel-tsx" */),
  "component---src-pages-prismic-page-url-tsx": () => import("./../../../src/pages/{prismicPage.url}.tsx" /* webpackChunkName: "component---src-pages-prismic-page-url-tsx" */),
  "component---src-pages-request-order-request-id-tsx": () => import("./../../../src/pages/requestOrder/[requestId].tsx" /* webpackChunkName: "component---src-pages-request-order-request-id-tsx" */),
  "component---src-pages-requests-tsx": () => import("./../../../src/pages/requests.tsx" /* webpackChunkName: "component---src-pages-requests-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-theme-tsx": () => import("./../../../src/pages/theme.tsx" /* webpackChunkName: "component---src-pages-theme-tsx" */)
}

