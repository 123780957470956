import React, { useMemo, useReducer } from 'react';
import {
  actions,
  oneWayReducer,
  initialState,
  OneWayStatus,
  OneWayTabs,
} from '../state/oneway/reducers';
import UserType from '../models/UserType.model';

export type OneWayContextType = {
  backofficeStatusTabUserType: UserType;
  backofficeStatusTab: OneWayStatus;
  backofficeSettingsRender: OneWayTabs;
  updateBackofficeStatusTabUserType: (userType: UserType) => void;
  updateBackofficeStatusTab: (status: OneWayStatus) => void;
  updateBackofficeSettingsRender: (value: OneWayTabs) => void;
};

const defaultOneWayContextValues: OneWayContextType = {
  backofficeStatusTabUserType: initialState.backofficeStatusTabUserType,
  backofficeStatusTab: initialState.backofficeStatusTab,
  backofficeSettingsRender: initialState.backofficeSettingsRender,
  updateBackofficeStatusTabUserType: () => {},
  updateBackofficeStatusTab: () => {},
  updateBackofficeSettingsRender: () => {},
};

export const OneWayContext = React.createContext<OneWayContextType>(defaultOneWayContextValues);

const OneWayProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(oneWayReducer, initialState);

  const value = useMemo(
    () => ({
      backofficeStatusTabUserType: state.backofficeStatusTabUserType,
      backofficeStatusTab: state.backofficeStatusTab,
      backofficeSettingsRender: state.backofficeSettingsRender,
      updateBackofficeStatusTabUserType: (userType: UserType) => {
        dispatch({ type: actions.UPDATE_BACKOFFICE_TAB_STATUS_USER_TYPE, payload: userType });
      },
      updateBackofficeStatusTab: (status: OneWayStatus) => {
        dispatch({ type: actions.UPDATE_BACKOFFICE_TAB_STATUS, payload: status });
      },
      updateBackofficeSettingsRender: (value: OneWayTabs) => {
        dispatch({ type: actions.UPDATE_BACKOFFICE_SETTINGS_STATUS, payload: value });
      },
    }),
    [state],
  );

  return <OneWayContext.Provider value={value}>{children}</OneWayContext.Provider>;
};

export default OneWayProvider;
