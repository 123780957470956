import DataAccountDetails from 'models/data-account-details.model';
import { UserProfile } from 'models/user-profile.model';
import React, { useMemo, useReducer } from 'react';
import ErrorHelper from '../helpers/ErrorHelper';
import {
  getToken,
  getUser,
  logout,
  setToken,
  setUser,
} from '../services/api/auth-session-storage.service';
import { createToken, SignInType } from '../services/api/auth.service';
import { getUserProfile, updateProfileDetails } from '../services/api/user.service';
import { actions, initialState, userReducer } from '../state/user/reducers';
import Partner from '../models/partner.model';

export type UserContextType = {
  profile: UserProfile;
  resetLoggedUser: () => void;
  updateAuthToken: (token: string) => void;
  signInUser: (email: string, password: string, signInType: SignInType) => Promise<void>;
  signInPartner: (email: string, password: string, signInType: SignInType) => Promise<void>;
  isUserLoggedIn: boolean;
  updateProfileDetails: (email: string, userDetails: DataAccountDetails) => Promise<void>;
  hasUserChosenGuest: boolean;
  updateHasUserChosenGuest: (hasUserChosenGuest: boolean) => void;
  authToken: string;
};

const defaultUserContextValues: UserContextType = {
  profile: undefined,
  resetLoggedUser: () => {},
  updateAuthToken: () => {},
  signInUser: () => Promise.resolve(),
  signInPartner: () => Promise.resolve(),
  isUserLoggedIn: false,
  updateProfileDetails: () => Promise.resolve(),
  hasUserChosenGuest: false,
  updateHasUserChosenGuest: () => {},
  authToken: '',
};

export const UserContext = React.createContext<UserContextType>(defaultUserContextValues);

const UserProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  const signIn = async (email: string, password: string, signInType: SignInType) => {
    try {
      await createToken(email, password, signInType);
      const resp = await getUserProfile(signInType);
      if (signInType === SignInType.USER) {
        dispatch({ type: actions.UPDATE_USER_PROFILE, payload: resp.data as UserProfile });
        setUser(resp.data as UserProfile);
      } else {
        const partner = resp.data as Partner;
        dispatch({ type: actions.UPDATE_PARTNER_PROFILE, payload: partner });
        setUser({
          authInfo: {
            isValid: partner.authInfo.isValid,
          },
          billingInfo: {
            billingFirstName: partner.billingInfo.billingFirstName,
            billingLastName: partner.billingInfo.billingLastName,
            billingMobilePhoneCountryCode: partner.billingInfo.billingMobilePhoneCountryCode,
            billingMobilePhoneNumber: '',
            billingTelephone: partner.billingInfo.billingMobilePhoneNumber,
            billingCity: partner.billingInfo.billingCity,
            billingAddress: partner.billingInfo.billingAddress,
            billingCountry: partner.billingInfo.billingCountry,
            billingPostalCode: partner.billingInfo.billingPostalCode,
          },
          contactInfo: {
            isBillingAddress: false,
            transporterNote: '',
            country: partner.addressInfo.country,
            addressComplement: partner.addressInfo.address2,
            city: partner.addressInfo.city,
            contactName: partner.contactInfo.contactName,
            postalCode: partner.addressInfo.postalCode,
            address: partner.addressInfo.address1,
            fixePhoneCountryCode: '',
            fixePhoneNumber: '',
            mobilePhoneCountryCode: partner.contactInfo.phoneCountryCode,
            mobilePhoneNumber: partner.contactInfo.phone,
            lastName: '',
            firstName: partner.contactInfo.personOfContact,
            title: partner.contactInfo.title,
          },
          roles: partner.roles,
          vatNumber: partner.contactInfo.vatNumber,
          vatCountryCode: partner.contactInfo.vatCountryCode,
          email: partner.contactInfo.email,
          _id: partner.id,
          accountType: 'professional',
        });
      }
    } catch (error) {
      ErrorHelper.handleError(error);
      throw error;
    }
  };

  const updateProfile = async (email: string, userDetails: DataAccountDetails) => {
    try {
      const resp = await updateProfileDetails(email, userDetails);
      dispatch({ type: actions.UPDATE_USER_PROFILE, payload: resp.data });
      setUser(resp.data);
    } catch (error) {
      ErrorHelper.handleError(error);
      throw error;
    }
  };

  const value = useMemo(
    () => ({
      authToken: getToken(),
      profile: getUser() || state.profile,
      signInUser: (email: string, password: string, signInType: SignInType) => {
        return signIn(email, password, signInType);
      },
      signInPartner: (email: string, password: string, signInType: SignInType) => {
        return signIn(email, password, signInType);
      },
      resetLoggedUser: () => {
        dispatch({ type: actions.RESET_LOGGED_USER });
        logout();
      },
      updateAuthToken: (token: string) => {
        setToken(token);
        dispatch({ type: actions.UPDATE_AUTH_TOKEN, payload: token });
      },
      isUserLoggedIn: Boolean(getUser()) || Boolean(state.profile),
      updateProfileDetails: (email: string, userDetails: DataAccountDetails) => {
        return updateProfile(email, userDetails);
      },
      hasUserChosenGuest: state.hasUserChosenGuest,
      updateHasUserChosenGuest: (hasUserChosenGuest: boolean) => {
        dispatch({ type: actions.UPDATE_HAS_USER_CHOSEN_GUEST, payload: hasUserChosenGuest });
      },
    }),
    [state],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
