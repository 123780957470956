import axios, { AxiosResponse } from 'axios';
import DataSignup from 'models/data-signup.model';
import { PasswordRecover } from 'models/password-recover.model';
import { setToken } from './auth-session-storage.service';

const updateHttpClientToken = (token: string): void => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const apiUrlPath = 'auth';

export enum SignInType {
  USER = 'user',
  PARTNER = 'partner',
}

type Token = {
  accessToken: string;
};
export const createToken = async (email: string, password: string, signInType: SignInType) => {
  const tokenResp: AxiosResponse<Token> = await axios.post(`${apiUrlPath}/signin/${signInType}`, {
    email,
    password,
  });
  setToken(tokenResp.data.accessToken);
  updateHttpClientToken(tokenResp.data.accessToken);
};

export const signUp = async (userData: DataSignup): Promise<void> => {
  return axios.post(`${apiUrlPath}/signup`, userData);
};

export const verifyEmail = (token: string): Promise<void> => {
  const data = { token };
  return axios.post(`${apiUrlPath}/validateUserEmail`, data);
};

export const forgotPassword = (email: string): Promise<void> => {
  return axios.post(`${apiUrlPath}/sendPasswordRecoverEmail/${email}`);
};

export const validatePasswordRecover = (
  recoverToken: string,
  passwordRecoverDto: PasswordRecover,
): Promise<void> => {
  return axios.post(`${apiUrlPath}/validatePasswordRecoverEmail`, {
    ...passwordRecoverDto,
    token: recoverToken,
  });
};
