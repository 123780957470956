import { AxiosInstance, AxiosResponse } from 'axios';
import RevolutOrderDto from '../../models/revolut-order-dto.model';
import { PaymentType } from '../../models/shipment.model';
import ColisportApiResource from './colisport-api-resource.model';
import { RefundOrderDtoResponse } from '../../models/refund-order-response.model';
import StripeCustomer from '../../models/stripe-customer.model';

export default class PaymentApi implements ColisportApiResource {
  readonly apiUrlPath = '/payment';

  constructor(readonly httpClient: AxiosInstance) {}

  createPayment(
    paymentType: PaymentType,
    finalPrice: number,
    colisportID?: string,
    stripe_email?: string,
    stripe_name?: string,
    isOneWay?: boolean,
  ): Promise<
    AxiosResponse<RevolutOrderDto | { colisportID: string; stripeCustomer?: StripeCustomer }>
  > {
    return colisportID
      ? this.httpClient.post(`${this.apiUrlPath}`, {
          paymentType,
          finalPrice,
          colisportID,
          stripe_email,
          stripe_name,
          isOneWay,
        })
      : this.httpClient.post(`${this.apiUrlPath}`, {
          paymentType,
          finalPrice,
          undefined,
          stripe_email,
          stripe_name,
          isOneWay,
        });
  }

  refundPayment(
    paymentType: PaymentType,
    colisportID: string,
    amount: number,
    isOneWay?: boolean,
  ): Promise<AxiosResponse<RefundOrderDtoResponse>> {
    return this.httpClient.post(`${this.apiUrlPath}/refund`, {
      paymentType,
      colisportID,
      amount,
      isOneWay,
    });
  }
}
